<template>
  <Content full>
    <div class="page--search">
      <div class="search-area" v-click-outside="closeKeyboard">
        <div class="search-box">
          <input ref="input" type="text" placeholder="Search" v-model="query" @focus="showKeyboard = true" />
          <g-icon-search />
        </div>

        <div class="keyboard-wrapper" :class="{ show: showKeyboard }">
          <g-keyboard :input="query" :layout="layout" @onChange="onChange" />
        </div>
      </div>

      <div class="search-results">
        <g-navbar class="search-type" :routes="routes" param="result" />

        <div v-show="result === 'all' || result === 'shops' || result === 'restaurants'" class="search-group shops">
          <g-title :title="`${$t('search.shops')} (${shops.length})`" subTitle="">
            <template #right>
              <g-button icon="arrow-right" :to="{ name: 'stores', params: { type: 'shops', filter: 'all' } }">
                {{ $t('search.seeAll') }}
              </g-button>
            </template>
          </g-title>
          <g-swiper :loop="false" :centeredSlides="false">
            <g-store-card
              class="swiper-slide"
              v-for="(store, i) in [...shops, ...restaurants]"
              :key="i"
              :store="store"
              :action="$t('search.learnMore')"
            />
          </g-swiper>
        </div>

        <div v-show="result === 'all' || result === 'services'" class="search-group services">
          <g-title :title="`${$t('search.services')} (${services.length})`" subTitle="">
            <template #right>
              <g-button icon="arrow-right" :to="{ name: 'services' }">{{ $t('search.seeAll') }}</g-button>
            </template>
          </g-title>
          <g-swiper :loop="false" :centeredSlides="false">
            <g-service-card
              class="swiper-slide"
              v-for="(service, i) in services"
              :key="i"
              :service="service"
              action=""
              @action="$router.push({ name: 'service', params: { id: service.id } })"
            />
          </g-swiper>
        </div>

        <div v-show="result === 'all' || result === 'events'" class="search-group events">
          <g-title :title="`${$t('search.events')} (${events.length})`" subTitle="">
            <template #right>
              <g-button icon="arrow-right" :to="{ name: 'agendas', params: { type: 'news', filter: 'all' } }">
                {{ $t('search.seeAll') }}
              </g-button>
            </template>
          </g-title>
          <g-swiper :loop="false" :centeredSlides="false">
            <g-event-card
              class="swiper-slide"
              v-for="(event, i) in events"
              :key="i"
              :event="event"
              action=""
              :to="{ name: 'agenda', params: { id: event.id } }"
            />
          </g-swiper>
        </div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { search } from 'glooh-globals/src/store/project';
import Content from '@/components/layout/content/Content.vue';
import { useAnalytics } from 'glooh-globals/src/plugins/misc/analytics';

export default defineComponent({
  components: {
    Content,
  },
  computed: {
    result(): string {
      return this.$route.params.result as string;
    },
    events(): any[] {
      return this.results.events ?? [];
    },
    restaurants(): any[] {
      return this.results.shops.filter((s: any) => s?.isRestaurant) ?? [];
    },
    services(): any[] {
      return this.results.services ?? [];
    },
    shops(): any[] {
      return this.results.shops.filter((s: any) => s?.tags?.includes('shop')) ?? [];
    },
    promos(): any[] {
      return this.results.promos ?? [];
    },
  },
  data: function () {
    return {
      query: '',
      showKeyboard: false,
      layout: {
        default: [
          'A Z E R T Y U I O P {bksp} -  7 8 9 ',
          'Q S D F G H J K L M @ & _  4 5 6 ',
          'W X C V B N {space} {enter} . #  1 2 3 0',
        ],
      },
      results: {
        events: [],
        restaurants: [],
        services: [],
        shops: [],
        promos: [],
      } as ProjectSearch,
      routes: [
        {
          params: { result: 'all' },
          text: this.$t('search.seeAll'),
        },
        {
          params: { result: 'shops' },
          text: this.$t('search.shops'),
        },
        {
          params: { result: 'services' },
          text: this.$t('search.services'),
        },
        {
          params: { result: 'events' },
          text: this.$t('search.events'),
        },
      ],
    };
  },
  watch: {
    query: {
      handler() {
        this.results = search(this.query, true);

        const { track } = useAnalytics();
        track('search', { query: this.query });
      },
      immediate: true,
    },
  },
  methods: {
    onChange(input: string) {
      this.query = input;
    },
    closeKeyboard() {
      this.showKeyboard = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--search {
    --title-line-margin: 0;
    --title-horizontal-spacing: 2vmax;

    --keyboard-background: black;
    --keyboard-background-numpad: #888;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 4rem 3rem;
    overflow: auto;

    h1 {
      font-size: 3vmax;
      padding: 0;
    }

    .keyboard-wrapper {
      height: 0;
      overflow: hidden;
      transition: height 0.25s ease-in-out, margin 0.25s ease-in-out;

      &.show {
        height: 8.5vmax;
        margin-top: 1vmax;
      }
    }

    .simple-keyboard {
      margin: 0;
      padding: 0;
      transform: scale(0.5) translate(0, -8rem);
    }

    .search-area {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .search-box {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1.5vmax;
      background: #f5f5f3;
      color: #27263a;
      padding: 1vmax;
      width: 100%;

      svg {
        margin-left: 1rem;
        width: 2vmax;
        height: 2vmax;
      }

      input {
        border: 0;
        outline: none;
        background: transparent;
        flex: 1;
        color: #27263a;
        &::placeholder {
          text-transform: uppercase;
          color: #27263a;
        }
      }
    }

    .search-results {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 1vmax 0;
    }

    .nav-wrapper {
      --button-padding: 1rem;
      --navbar-display: flex;
      --button-background-active: transparent;
      width: 100%;
    }

    .search-group {
      display: flex;
      flex-direction: column;

      width: 100%;

      &:not(:first-child) {
        margin-top: 2vmax;
      }

      :deep(.title-wrapper) {
        margin-bottom: 1vmax;
        .header {
          .title {
            text-align: left;
            margin-bottom: 1vmax;
            font-size: 1.5rem;
            line-height: 1.5rem;
            text-transform: uppercase;
          }
        }
      }
    }

    :deep(.search-type) {
      .navbar {
        font-size: 1.5vmax;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0.5rem 0;

        .nav-item {
          &:not(:first-child) {
            margin-left: 1rem;
          }

          a {
            padding: 0.25rem 3rem;
          }
        }
      }
    }

    .swiper-container {
      .swiper-slide {
        margin: 0 0.5vmax;
      }
    }
  }
}
</style>
