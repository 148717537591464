<template>
  <div class="loading-wrapper">
    <svg
      width="64"
      height="64"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      stroke-width="4"
    >
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(2 2)">
          <circle stroke-opacity=".25" cx="14" cy="14" r="14" />
          <path d="M28 14c0-9.94-8.06-14-14-14">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 14 14"
              to="360 14 14"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({});
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background: var(--app-background);
  color: var(--app-foreground);
}
</style>
