import { App, Plugin } from 'vue';

import IconNavHome from '@/components/icons/IconNavHome.vue';
import IconNavStores from '@/components/icons/IconNavStores.vue';
import IconNavAgenda from '@/components/icons/IconNavAgenda.vue';
import IconNavServices from '@/components/icons/IconNavServices.vue';
import IconNavPlan from '@/components/icons/IconNavPlan.vue';
import IconNavPromos from '@/components/icons/IconNavPromos.vue';
import IconNavSearch from '@/components/icons/IconNavSearch.vue';
import IconNavMenu from '@/components/icons/IconNavMenu.vue';

import IconNavGift from '@/components/icons/IconNavGift.vue';
import IconNavPMR from '@/components/icons/IconNavPMR.vue';
import IconNavFB from '@/components/icons/IconNavFB.vue';
import IconNavInsta from '@/components/icons/IconNavInsta.vue';
import IconNavNewsletter from '@/components/icons/IconNavNewsletter.vue';

import IconNavTime from '@/components/icons/IconNavTime.vue';
import IconNavRestaurants from '@/components/icons/IconNavRestaurants.vue';
import IconCamera from '@/components/icons/IconCamera.vue';
import IconLoyalty from '@/components/icons/IconLoyalty.vue';
import IconHere from '@/components/icons/IconHere.vue';
import IconNavApp from '@/components/icons/IconNavApp.vue';

import Store from '@/views/pages/single/Store.vue';
import Service from '@/views/pages/single/Service.vue';

import EventCard from '@/components/cards/EventCard.vue';
import PromoCard from '@/components/cards/PromoCard.vue';
import ServiceCard from '@/components/cards/ServiceCard.vue';
import StoreCard from '@/components/cards/StoreCard.vue';

import CachedImage from '@/components/misc/CachedImage.vue';

function installIcons(app: App) {
  app.component('cimg', CachedImage);

  app.component('g-icon-nav-home', IconNavHome);
  app.component('g-icon-nav-app', IconNavApp);
  app.component('g-icon-nav-stores', IconNavStores);
  app.component('g-icon-nav-agenda', IconNavAgenda);
  app.component('g-icon-nav-fb', IconNavFB);
  app.component('g-icon-nav-insta', IconNavInsta);
  app.component('g-icon-nav-services', IconNavServices);
  app.component('g-icon-nav-plan', IconNavPlan);
  app.component('g-icon-nav-promos', IconNavPromos);
  app.component('g-icon-nav-gift', IconNavGift);
  app.component('g-icon-nav-newsletter', IconNavNewsletter);
  app.component('g-icon-nav-search', IconNavSearch);
  app.component('g-icon-nav-menu', IconNavMenu);
  app.component('g-icon-nav-restaurants', IconNavRestaurants);
  app.component('g-icon-nav-time', IconNavTime);
  app.component('g-icon-nav-pmr', IconNavPMR);
  app.component('g-icon-camera', IconCamera);
  app.component('g-icon-loyalty', IconLoyalty);
  app.component('g-icon-here', IconHere);

  app.component('g-event-card', EventCard);
  app.component('g-promo-card', PromoCard);
  app.component('g-service-card', ServiceCard);
  app.component('g-store-card', StoreCard);

  app.component('store-page', Store);
  app.component('service-page', Service);
}

let installed = false;
export default {
  install(app: App) {
    if (installed) return;
    installed = true;

    installIcons(app);
  },
} as Plugin;
