<template>
  <div class="page--menu">
    <section class="news">
      <g-title :title="$t('home.news')" compressed :right="false">
        <template #right>
          <g-button icon="arrow-right" :to="{ name: 'agendas', params: { type: 'now', filter: 'all' } }">
            {{ $t('home.seeAll') }}
          </g-button>
        </template>
      </g-title>

      <g-swiper :loop="false" centeredSlides>
        <g-slide v-for="(slide, i) in events" :key="i" :to="{ name: 'agenda', params: { id: slide?.id } }">
          <cimg class="banner" :src="slide.banner ?? slide.images[0]" />
          <template #title>{{ getLocaleValue(slide.title) }}</template>
        </g-slide>
      </g-swiper>
    </section>

    <section class="menu-lg">
      <div
        class="menu-item"
        v-for="(route, i) of routes3"
        :key="i"
        @click="goToRoute(route)"
        :style="{ backgroundColor: route.background ?? 'transparent', color: route.color ?? 'black' }"
      >
        <cimg v-if="route.icon" :src="route.icon" />
        <span v-html="getLocaleValue(route.title)"></span>
      </div>
    </section>

    <section class="menu">
      <div class="menu-item" v-for="(route, i) of routes" :key="i" @click="goToRoute(route)">
        <cimg v-if="route.icon" :src="route.icon" />
        <span v-html="getLocaleValue(route.title)"></span>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

import project from 'glooh-globals/src/store/project';

export default defineComponent({
  computed: {
    events(): any[] {
      const events = project.events.all();
      return events;
    },
    routes3(): any[] {
      const { id: parent_id } = project.menus.all().find((f: any) => f.key === 'home-menu-lg') ?? { id: -1 };
      return project.menus.findBy({ parent_id }) ?? [];
    },
    routes(): any[] {
      const { id: parent_id } = project.menus.all().find((f: any) => f.key === 'home-menu') ?? { id: -1 };
      return project.menus.findBy({ parent_id }) ?? [];

      /* return [{
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 's'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }, {
        target_id: 44,
        target_type: 'service',
        icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
        title: 'Test'
      }]; */
    },
  },
  methods: {
    getLocaleValue,
    goToRoute(route: any) {
      if (route.target_type === 'route') {
        return this.$router.push(route.target_id);
      }
      if (route.target_type === 'service') {
        return this.$router.push({ name: 'service', params: { id: route.target_id } });
      }
      console.log(route);
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    /*    gap: 2.5vmax;*/
    outline: 2px solid red !important;
    flex: 1;

    height: 100%;
    overflow: auto;

    --title-text-align: left;
    --title-line-max-width-left: 2rem;

    section {
      display: flex;
      flex-direction: column;
      margin-top: 2vmax;
      /*      gap: 2.5rem;*/
      outline: 2px solid red !important;

      --button-direction: row-reverse;
      --button-background: #fed931;

      .title-wrapper {
        padding: var(--swiper-navigation-padding);
      }

      &.menu,
      &.menu-lg {
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        padding: var(--swiper-navigation-padding);

        .menu-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /*          gap: 1rem;*/
          outline: 2px solid red !important;
          width: 100%;
          height: 100%;
          background-color: #fff;
          cursor: pointer;

          svg,
          img {
            width: 4rem;
            height: 4rem;
            object-fit: contain;
          }
        }
      }

      &.menu-lg {
        margin: 0;
        display: grid;
        grid-auto-flow: column dense;
        grid-auto-columns: 17rem;
        grid-template-rows: 10rem;

        .menu-item {
          flex-direction: row;
          font-size: 1.5rem;
        }
      }

      &.menu {
        margin: 0;
        display: grid;
        /*        gap: 1.5rem;*/
        outline: 2px solid red !important;
        grid-auto-flow: column dense;
        grid-auto-columns: 10rem;
        grid-template-rows: 10rem 10rem;
      }

      &.news {
        --swiper-content-padding: 2vmax;
        flex: 1 0;
        overflow: hidden;

        :deep(.swiper) {
          flex: 1 0;

          .swiper-container {
            height: 100%;

            .swiper-slide {
              width: 66rem;
              min-width: 66rem;
              height: 100%;
              max-height: 100%;
              margin: 1rem;

              .content {
                padding: 0 !important;
              }

              span {
                display: flex;
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide--image {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
