<template>
  <div class="nav-wrapper">
    <div class="background" :style="background" />
    <nav ref="nav" class="navbar" :class="classes">
      <div
        class="nav-item"
        :class="{ fill: route === '-', inactive: route.disabled && !isDev }"
        v-for="(route, i) of routes"
        :key="i"
      >
        <g-button
          :ref="(param && route.params ? route.params[param] : route.name) || route.name"
          v-bind="{
            to: route.action ? undefined : { name: route.name, params: route.params },
            href: route.action ? '#' : undefined,
          }"
          v-on="{ click: () => route.action && $emit(route.action) }"
          :icon="route.icon"
          iconSize="3vmax"
          vertical
          noHover
          transparent
          v-if="route !== '-'"
        >
          <span v-html="typeof route.text === 'function' ? route.text() : route.text" v-if="route.text" />
        </g-button>
      </div>
      <slot name="end" />
    </nav>
  </div>
</template>

<script lang="ts">
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import Button from 'glooh-globals/src/components/controls/Button.vue';
import { RouteLocation, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export default defineComponent({
  props: {
    routes: {
      type: Array as PropType<Array<any>>,
      default: [],
    },
    exact: {
      type: Boolean,
      default: false,
    },
    param: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDev: (import.meta as any).env.DEV,
      observer: undefined as ResizeObserver | undefined,
      background: {
        transform: 'translate(0, 0)',
        transition: 'none',
        opacity: 0,
        width: '0px',
        height: '100%',
      },
      updateBackground: _.throttle(function (this: any, ...params) {
        this.updateBackgroundRaw(...params);
      }, 100),
    };
  },
  computed: {
    classes(): object {
      return {
        'navbar--exact': this.exact,
      };
    },
  },
  watch: {
    $route(to, from) {
      this.checkForUpdates(to, from);
    },
  },
  methods: {
    checkForUpdates(
      to: RouteLocationNormalized | undefined,
      from: RouteLocationNormalized | undefined,
      initial = false,
    ) {
      const toName = (this.param && to?.params ? to.params[this.param] : to?.name) || to?.name;
      const fromName = (this.param && from?.params ? from.params[this.param] : from?.name) || from?.name;
      this.updateBackground(toName, fromName, initial);
    },
    updateBackgroundRaw(name: string | null | undefined, oldName: string | null | undefined, initial = false) {
      if (!name) {
        return;
      }

      const button = (this.$refs[name] as typeof Button)?.$el as HTMLElement;
      if (!button) {
        if (oldName) {
          this.background.opacity = 0;
        }
        return;
      }

      if (this.observer) {
        if (oldName) {
          const oldButton = (this.$refs[oldName] as typeof Button)?.$el as HTMLElement;
          if (oldButton) this.observer.unobserve(oldButton);
        }
        this.observer.observe(button);
      }

      this.background.transform = `translate(${button.offsetLeft}px, ${button.offsetTop}px)`;
      this.background.width = `${button.offsetWidth}px`;
      this.background.height = `${button.offsetHeight}px`;
      this.background.opacity = 1;

      if (!initial) this.background.transition = 'all 0.125s linear';
    },
  },
  mounted() {
    this.observer = new ResizeObserver((e) => this.checkForUpdates(this.$route, undefined, true));
    this.observer.observe(this.$refs.nav as Element);
    this.checkForUpdates(this.$route, undefined, true);
  },
});
</script>

<style lang="scss" scoped>
.nav-wrapper {
  position: relative;
  --button-padding: 0.75rem 2rem;

  .navbar {
    display: var(--navbar-display);
    justify-content: center;
    align-items: center;
    table-layout: fixed;
    position: relative;
    width: 100%;

    .nav-item {
      display: var(--navbar-item-display);

      &.fill {
        flex: 1;
      }

      &.inactive {
        pointer-events: none;
        filter: grayscale(1);
        background: #efefef;
        opacity: 0.25;
      }

      :deep(.g-btn) {
        width: 100% !important;

        svg {
          height: 2rem !important;
        }
      }
    }

    a {
      color: var(--navbar-anchor-foreground);
    }

    &:not(.navbar--exact) {
      a.router-link-active {
        color: var(--navbar-anchor-foreground-active);
        transition-delay: 0.125s;
      }
    }

    &--exact {
      a.router-link-exact-active {
        color: var(--navbar-anchor-foreground-active);
        transition-delay: 0.125s;
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;

    background-color: var(--navbar-anchor-background);
    transform: translateX(-9vmax);
    transition: color 0.25s linear;

    pointer-events: none;
  }
}
</style>
