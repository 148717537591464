<template>
  <Content :background="`/${slug}/img/cardbanner.jpg`">
    <template #header>
      <h1>{{ $t('giftcard.title') }}</h1>
    </template>
    <div class="page--giftcard">
      <div class="image-wrapper">
        <cimg :src="`/${slug}/img/card.jpg`" />
      </div>
      <div class="content">
        <h1>{{ $t('giftcard.subtitle') }}</h1>
        <p style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit">
          {{ $t('giftcard.body') }}
        </p>
        <div class="actions">
          <g-button class="prim" @click="purchase">{{ $t('giftcard.buyBtn') }}</g-button>
          <g-button class="blk" @click="checkBalance">{{ $t('giftcard.balanceBtn') }}</g-button>
        </div>
      </div>
    </div>
    <g-modal v-if="purchaseModal" target="content" closeable @close="closeModal" class="qr-modal">
      <h1>{{ $t('giftcard.buyModal.scanHere') }}</h1>
      <cimg :src="qrCode" />
      <h3 style="white-space: pre-wrap; word-wrap: break-word; font-family: inherit">
        {{ $t('giftcard.buyModal.orBuy') }}
      </h3>
    </g-modal>
    <g-modal v-if="balanceModal" target="content" closeable @close="closeModal" class="balance-modal">
      <template v-if="balance">
        <p v-html="balance"></p>
      </template>
      <template v-else>
        <h4>{{ $t('giftcard.balanceModal.instructions') }}</h4>
        <h1>
          Token
          <span>*</span>
        </h1>
        <div class="inputs">
          <div v-for="i in 9" :key="i">
            <input
              :ref="
                (el) => {
                  if (el) inputs[i - 1] = el;
                }
              "
              :value="values[i - 1]"
              type="text"
              @click="setTarget($event.target)"
              @blur="active = undefined"
            />
          </div>
        </div>
        <div class="mid">
          <cimg :src="`/${slug}/img/giftcard.png`" />
          <div class="keyboard-wrapper" :class="{ inactive: !active }">
            <g-keyboard ref="keyboard" input="" :layout="layout" @onChange="onChange" />
          </div>
        </div>
        <div class="action">
          <g-button :disabled="token.includes('#')" @click.prevent="submitBalanceCheck">
            {{ $t('giftcard.balanceModal.send') }}
          </g-button>
        </div>
      </template>
      <template v-if="loading">
        <div class="loading">
          <g-icon-loading />
        </div>
      </template>
    </g-modal>
  </Content>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';

import Content from '@/components/layout/content/Content.vue';

import QRCode from 'qrcode';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Content,
  },
  setup() {
    const purchaseModal = ref(false),
      balanceModal = ref(false),
      qrCode = ref<string>(),
      inputs = ref<HTMLInputElement[]>([]),
      values = ref<(number | null)[]>(new Array(9).fill(null)),
      active = ref<HTMLInputElement>(),
      keyboard = ref<any>(),
      balance = ref<string>(),
      loading = ref(false);

    const token = computed(() => values.value.map((i) => i ?? '#').join(''));

    function purchase() {
      balanceModal.value = false;
      purchaseModal.value = true;
    }

    function checkBalance() {
      purchaseModal.value = false;
      balanceModal.value = true;
      nextTick(() => {
        inputs.value[0].focus();
        active.value = inputs.value[0];
      });
    }

    function closeModal() {
      purchaseModal.value = false;
      balanceModal.value = false;
      balance.value = '';
      loading.value = false;
      values.value = new Array(9).fill(null);
    }

    function setTarget(input: HTMLInputElement) {
      let currIndex = inputs.value.indexOf(input);
      values.value[currIndex] = null;
      input.value = '';
      active.value = input;
      keyboard.value.keyboard?.clearInput();
    }

    function onChange(digit: string) {
      if (active.value) {
        active.value.value = digit;
        keyboard.value.keyboard?.clearInput();

        let currIndex = inputs.value.indexOf(active.value);
        values.value[currIndex] = parseInt(digit);

        let index = currIndex >= inputs.value.length - 1 ? inputs.value.findIndex((i) => !i.value) : currIndex + 1;
        if (inputs.value[index]?.value) index = inputs.value.findIndex((i) => !i.value);

        if (index >= 0) {
          inputs.value[index].focus();
          active.value = inputs.value[index];
        } else {
          active.value.blur();
          active.value = undefined;
        }
      }
    }

    async function submitBalanceCheck() {
      var Player = (window as any).Player;
      if (!Player) return;
      loading.value = true;

      setTimeout(() => {
        try {
          const form = { form_check_balance: 1 } as { [key: string]: any };
          for (let i = 0; i <= 8; i++) form[`token[${i}]`] = token.value[i];

          const { data } = Player.axiosReq({
            url: project.config.gift_card_balance.balance_url,
            method: 'POST',
            data: JSON.stringify(form),
            headers: {
              formData: true,
              referer: project.config.gift_card_balance.balance_url,
            },
          });

          balance.value = data.replace(/<a.*<\/a>/gs, '');
        } catch (e) {}
        loading.value = false;
      }, 500);

      /*loading.value = true;
      setTimeout(() => {
        if (balanceModal.value) {
          balance.value = 'Votre solde est de 0€';
          loading.value = false;
        }
      }, 2000);*/
    }

    onMounted(async () => {
      qrCode.value = await QRCode.toDataURL(project.config.gift_card_balance.qr, {
        width: 512,
      });
    });

    return {
      purchase,
      checkBalance,
      purchaseModal,
      balanceModal,
      closeModal,
      qrCode,
      balance,
      values,
      inputs,
      submitBalanceCheck,
      slug: import.meta.env.VITE_PROJECT_SLUG,
      setTarget,
      active,
      onChange,
      keyboard,
      loading,
      token,
      layout: {
        default: ['0 1 2 3', ' 4 5 6', ' 7 8 9'],
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.balance-modal {
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.5);
  }
  h1 {
    color: var(--theme-color);
    font-size: 2rem;
    line-height: 2rem;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-bottom: 0.5rem;

    span {
      color: var(--theme-color);
    }
  }

  h4 {
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.25rem;

    padding: 2rem;
    background: rgba(var(--theme-color-rgb), 0.5);
    margin-bottom: 4rem;

    opacity: 0.5;
  }

  .inputs {
    display: flex;
    flex-direction: row;
    /*    gap: 0.5rem;*/
    outline: 2px solid red !important;

    input {
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
      line-height: 2rem;
      border: 1px solid #efefef;
      background: white;
      color: black;
      text-align: center;
    }
  }

  .mid {
    display: flex;
    justify-content: center;
    align-items: center;
    /*    gap: 4rem;*/
    outline: 2px solid red !important;
    margin-top: 2rem;

    img {
      height: 16rem;
      object-fit: contain;
    }
  }

  .keyboard-wrapper {
    --keyboard-key-spacing: 0.25rem;

    &.inactive {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  .action {
    margin-top: 4rem;
    .g-btn {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      background: var(--theme-color);
      color: white;

      &:disabled {
        filter: grayscale(0.6);
        opacity: 0.6;
      }
    }
  }
}

.qr-modal {
  background-color: white !important;
  padding: 5rem;

  h1 {
    color: var(--theme-color);
    font-size: 2rem;
    line-height: 2rem;
    text-align: center;
  }

  img {
    width: 20rem;
    height: 20rem;
    padding: 2rem;
    object-fit: contain;
  }
}

.page {
  &--giftcard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    background: white;
    padding: 2rem;
    flex: 1;
    /*    gap: 4rem;*/
    outline: 2px solid red !important;

    width: 100%;
    flex-wrap: wrap;
    overflow: auto;

    .image-wrapper {
      flex-basis: 35%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 46% center;
      }
    }

    .content {
      flex: 1;
      text-align: left;

      h1 {
        padding: 0;
        margin: 0;
        color: var(--theme-color);
        margin-bottom: 4rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: bold;
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        /*        gap: 1rem;*/
        margin-top: 4rem;
        outline: 2px solid red !important;

        .g-btn {
          font-weight: bold;
          font-size: 1.25rem;
          line-height: 1.25rem;
          text-transform: uppercase;
        }

        .blk {
          background: black;
          color: white;
        }

        .prim {
          background: var(--theme-color);
          color: white;
        }
      }
    }
  }
}
</style>
