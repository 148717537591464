<template>
  <Content style="--header-dir: column; --header-img-width: 100%" modal>
    <template #header>
      <cimg :src="getImage((agenda?.images || [])[0])" style="margin-bottom: 2rem" />

      <h1 v-html="getLocaleValue(agenda?.title)"></h1>
    </template>
    <div class="page--store">
      <h2 style="padding: 0">
        <div v-if="agenda.date_start && agenda.date_end && agenda.date_start != agenda.date_end" class="long-date">
          <span>
            <strong>{{ formatdate(agenda.date_start, 'D') }}</strong>
          </span>
          <span>{{ formatdate(agenda.date_start, 'MMM') }}</span>
          <pre>—</pre>
          <span>
            <strong>{{ formatdate(agenda.date_end, 'D') }}</strong>
          </span>
          <span>{{ formatdate(agenda.date_end, 'MMM') }}</span>
        </div>
        <span class="long-date" v-else-if="agenda.date_start === agenda.date_end && agenda.type !== 'news'">
          {{ formatdate(agenda.date_start, 'D MMMM') }}
        </span>
        <span v-show="0" class="long-date" v-else-if="agenda.type === 'news'">{{ $t('eventCard.news') }}</span>
      </h2>
      <div class="desc">
        <div class="desc-content" v-html="agenda.body_long"></div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import _ from 'lodash';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import MapProvider from '@/components/map/MapProvider.vue';
import project from 'glooh-globals/src/store/project';
import Content from '@/components/layout/content/Content.vue';
import IconHere from '@/components/icons/IconHere.vue';
import { getImage } from '@/store/cache';

export default defineComponent({
  components: {
    MapProvider,
    Content,
    IconHere,
  },
  props: {
    id: {
      type: Number,
    },
  },
  computed: {
    agendaId(): number {
      return this.id || parseInt(this.$route.params.id as string);
    },
    agenda(): any {
      return project.events.find(this.agendaId);
    },
  },
  methods: {
    formatdate: (dateString: string, format: string) => {
      return moment(dateString, 'YYYY-MM-DDThh:mm:ssZ').format(format).toUpperCase();
    },
    getLocaleValue,
    getImage,
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--store {
    display: flex;
    flex-direction: column;

    flex: 1;
    flex-direction: column;
    width: 100%;
    font-size: 1.15vmax;
    line-height: 1.5vmax;
    color: black;
    background: white;
    overflow: visible;
    height: 100%;

    h2 {
      color: #00aeff;
      margin-bottom: 1vmax;
    }

    .desc {
      margin-top: 2rem;
      flex: 1;
      overflow: auto;
      text-align: left;
    }

    .long-date {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
      /*      gap: 0.25rem;*/
      outline: 2px solid red !important;

      * {
        overflow: hidden;
      }
    }
  }
}
</style>
