<template>
  <header class="main-header">
    <slot name="logo">
      <cdp-logo />
    </slot>
    <cdp-navigation @disabled="$emit('disabled')" @abled="$emit('abled')" />
    <cdp-header-stats />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CDPLogo from '@/components/misc/CDPLogo.vue';
import CDPHeaderStats from '@/components/layout/header/CDPHeaderStats.vue';
import CDPNavigation from '@/components/layout/navigation/CDPNavigation.vue';

export default defineComponent({
  components: {
    'cdp-logo': CDPLogo,
    'cdp-header-stats': CDPHeaderStats,
    'cdp-navigation': CDPNavigation,
  },
});
</script>

<style lang="scss" scoped>
.main-header {
  border-bottom: 0.15vmax solid #ddd;
  height: min-content;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: var(--header-direction);
  z-index: 500;
}

@media (orientation: portrait) {
  .main-header {
    .logo-wrapper {
      padding: 2vmax;
      height: 368px;
      width: 100%;

      background-position: center center;
      background-size: 100% auto;
    }

    .main-nav {
      width: 100%;
      background: white;
    }

    &--stats {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 2vmax;
      flex-direction: row-reverse;
      justify-content: space-between;
      color: black !important;
    }
  }
}

@media (orientation: landscape) {
  .main-header {
    flex-direction: row;
  }
}
</style>
