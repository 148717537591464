<template>
  <Content>
    <template #header>
      <h1 v-html="$t('events.title')"></h1>
    </template>
    <div class="page--agenda">
      <!-- <g-navbar class="store-type" :routes="routes" param="type" /> -->
      <DateFilter class="articles" :swiper="false" :items="agendas" :showFilter="$route.params.type === 'coming'">
        <template v-slot:default="article">
          <router-link :to="{ name: 'agenda', params: { id: article.id } }" class="article">
            <div v-if="article.banner ?? (article.images ?? [])[0]" class="img-wrapper">
              <cimg :src="article.banner ?? (article.images ?? [])[0]" alt="" />
            </div>
            <div class="article-content">
              <h2 v-html="getLocaleValue(article.title)"></h2>
              <div
                v-if="article.date_start && article.date_end && article.date_start != article.date_end"
                class="long-date"
              >
                <span>
                  <strong>{{ formatdate(article.date_start, 'D') }}</strong>
                </span>
                <span>{{ formatdate(article.date_start, 'MMM') }}</span>
                <span>{{ formatdate(article.date_start, 'YYYY') }}</span>
                <pre>—</pre>
                <span>
                  <strong>{{ formatdate(article.date_end, 'D') }}</strong>
                </span>
                <span>{{ formatdate(article.date_end, 'MMM') }}</span>
                <span>{{ formatdate(article.date_start, 'YYYY') }}</span>
              </div>
              <span class="long-date" v-else-if="article.date_start === article.date_end && article.type !== 'news'">
                {{ formatdate(article.date_start, 'D MMMM') }}
                {{ formatdate(article.date_start, 'YYYY') }}
              </span>
              <span class="long-date" v-else-if="article.type === 'news'">{{ $t('eventCard.news') }}</span>
              <p class="desc" v-html="article.body_long" />
              <router-link class="more" :to="{ name: 'agenda', params: { id: article.id } }">
                {{ $t('general.learnMore') }}
              </router-link>
            </div>
          </router-link>
        </template>
      </DateFilter>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DateFilter from '@/components/misc/DateFilter.vue';
import Content from '@/components/layout/content/Content.vue';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import project from 'glooh-globals/src/store/project';
import moment from 'moment';

export default defineComponent({
  components: {
    DateFilter,
    Content,
  },
  methods: {
    formatdate: (dateString: string, format: string) => {
      return moment(dateString, 'YYYY-MM-DDThh:mm:ss').format(format).toUpperCase();
    },
    getLocaleValue,
  },
  computed: {
    now() {
      return [...project.events.active().filter((e: any) => e.type === 'event')]; //.filter((e: any) => e.type === 'limited');
    },
    coming() {
      return [...project.events.all().filter((e: any) => e.type === 'event')];
    },
    news() {
      return [...project.events.active().filter((e: any) => e.type === 'news')];
    },
    agendas() {
      let _results = [];
      switch (this.$route.params.type) {
        /* case 'now':
          _results = this.now;
          break;
        case 'coming':
          _results = this.coming;
          break; */
        case 'news':
          _results = this.news;
          break;
      }

      _results = _results.sort((a, b) =>
        moment(b.date_start, 'YYYY-MM-DDThh:mm:ss').diff(moment(a.date_start, 'YYYY-MM-DDThh:mm:ss')),
      );

      return _results;
    },
  },
  data: function () {
    return {
      active: 'news',
      routes: [
        /* {
          params: { type: 'now' },
          icon: 'nav-agenda',
          text: this.$t('events.rightNow'),
        },
        {
          params: { type: 'coming' },
          icon: 'nav-agenda',
          text: this.$t('events.ourEvents'),
        }, */
        {
          params: { type: 'news' },
          icon: 'nav-agenda',
          text: this.$t('events.ourNews'),
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--agenda {
    --button-padding: 1vmax 4vmax;
    --navbar-display: flex;
    --button-background-active: transparent;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    padding-top: 2rem;
    flex: 1;
    overflow: hidden;

    h1 {
      font-size: 3vmax;
      padding: 0;
    }

    :deep(.store-type) {
      margin-bottom: 2rem;

      .navbar {
        /*        gap: 2rem;*/
        outline: 2px solid red !important;
      }
    }

    :deep(.articles) {
      position: relative;
      background: white;
      flex: 1;
      width: 100%;

      display: grid;
      grid-template-columns: minmax(auto, calc(50% - 2rem)) auto;
      overflow: auto;
      padding: 2rem;
      padding-top: 0;

      .article {
        display: flex;
        flex-direction: column;
        width: calc(100% - 2rem);
        overflow: hidden;
        height: 490px;
        margin: 0 1rem 2rem;

        .img-wrapper {
          width: 100%;
          min-width: 100%;
          overflow: hidden;
          max-height: 195px;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .article-content {
          background: #efefef;
          padding: 2rem;
          flex: 1;
          width: 100%;
          overflow: hidden;
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: left;

          h2 {
            font-size: 1.5em;
            line-height: 1.5em;
            padding: 0;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.025em;
          }

          .desc {
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            height: 8rem;

            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;

            * {
              line-height: 1rem;
              font-size: 1rem;
            }
          }

          .long-date {
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;

            * {
              overflow: hidden;
              margin: 0 0.125rem;
            }
          }

          .more {
            padding-top: 1rem;
            display: block;
          }
        }
      }
    }
  }
}
</style>
