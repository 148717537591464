<template>
  <div class="service-info">
    <h1>{{ getLocaleValue(service?.title) }}</h1>
    <p v-html="service?.description_long" />
    <g-button
      v-if="service.mapwize_id"
      class="btn-primary"
      icon="arrow-right"
      :to="{ name: 'plan', params: { placeId: service?.id } }"
    >
      EN SAVOIR PLUS
    </g-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

export default defineComponent({
  props: {
    service: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return { getLocaleValue };
  },
  mounted() {
    console.log(this.service);
  },
});
</script>

<style lang="scss" scoped>
.service-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  gap: 2rem;*/
  outline: 2px solid red !important;
  padding: 2rem;

  h1 {
    margin: 0;
    padding: 0;

    line-height: 2rem;
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .g-btn {
    --button-padding: 0.5rem 2rem;
    --button-direction: row-reverse;

    font-size: 0.85vmax;
    background: var(--theme-color);
    color: white;
    min-height: 3vmax;
  }
}
</style>
