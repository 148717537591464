<template>
  <div class="service-card">
    <g-button class="top" :to="{ name: 'service', params: { id: service?.id } }">
      <template v-if="service?.logo" #icon>
        <cimg :src="service?.logo" />
      </template>
      {{ getLocaleValue(service?.title) }}
    </g-button>
    <g-button v-if="action" class="action" icon="arrow-right" @click="$emit('action')">
      {{ action }}
    </g-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

import ServiceInfo from '@/components/misc/ServiceInfo.vue';

export default defineComponent({
  components: {
    ServiceInfo,
  },
  props: {
    service: {
      type: Object,
      default: undefined,
    },
    to: {
      type: Object,
      default: undefined,
    },
    action: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    return {
      getLocaleValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-card {
  --button-direction: column;
  --button-padding: 1rem;

  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px #0000000d;

  width: 8vmax;
  height: 8vmax;
  min-width: 8vmax;
  min-height: 8vmax;
  overflow: hidden;

  :deep(.content) {
    white-space: break-spaces;
  }

  .top {
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    background: white;

    img {
      max-width: 4rem;
      max-height: 4rem;
    }

    :deep(.content) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .action {
    --button-padding: 0.5rem;
    --button-direction: row-reverse;

    font-size: 0.85vmax;
    background: var(--theme-color);
    color: white;
    min-height: 3vmax;
    height: 3vmax;
  }
}
</style>
