<template>
  <Content full>
    <template #header>
      <h1>{{ $t('shops.ourShops') }}</h1>
    </template>
    <div class="page--stores">
      <Filter :stores="stores">
        <template v-slot:default="store">
          <g-store-card class="swiper-slide" :store="store" />
        </template>
      </Filter>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Filter from '@/components/misc/Filter.vue';
import Content from '@/components/layout/content/Content.vue';

import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Filter,
    Content,
  },
  computed: {
    restaurants() {
      return project.shops.all().filter((p: any) => p.tags?.includes('restaurant'));
    },
    shops() {
      return project.shops.all().filter((p: any) => p.tags?.includes('shop'));
    },
    stores() {
      switch (this.$route.params.type) {
        case 'restaurants':
          return this.restaurants;
        case 'shops':
          return this.shops;
        default:
          return project.shops.all();
      }
    },
  },
  data: () => ({
    active: '',
  }),
});
</script>

<style lang="scss" scoped>
.page {
  &--stores {
    --button-padding: 1vmax 4vmax;
    --navbar-display: flex;
    --button-background-active: transparent;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100%;
    overflow: hidden;

    :deep(.store-type) {
      margin-bottom: 2vmax;

      .navbar {
        /*        gap: 2vmax;*/
        outline: 2px solid red !important;
      }
    }
  }
}
</style>
