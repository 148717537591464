export default {
  datetimeFormats: {
    date: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    day: {
      day: '2-digit',
    },
    month: {
      month: 'short',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },

  messages: {
    back: "Terug",
    navbar: {
      search: 'Zoeken',
      home: 'Home',
      openings: 'Openingsuren',
      shops: 'Winkels',
      restaurants: 'Eten & Drinken',
      services: 'Diensten',
      giftcard: 'Gift Card',
      agendas: 'Nieuws & Events',
      newsletter: "Newsletter",
      map: 'Grondplan',
    },
    footer: {
      camera: 'Camera',
      disabled: 'PBM',
      regular:'groot scherm'

    },
    openings: {
      title: "Openingsuren",
      specialDays: "Bijzondere zon- en feestdagen",
      closed: "Gesloten"
    },
    home: {
      leftPanel: {
        cta: {
          access: 'BEKIJK HET',
          map: 'GRONDPLAN',
        },
      },
      news: 'Nieuws & Events',
      'news-sub': 'DECOUVRIR TOUS LES EVENEMENTS',
      services: 'Diensten',
      'services-sub': 'LES FACILITES',
      restaurants: 'Eten & Drinken',
      'restaurants-sub': 'SE RESTAURER OU BOIRE UN CAFÉ',
      shops: 'Winkels',
      'shops-sub': 'DECOUVRIR TOUTES NOS BOUTIQUES',
      seeAll: 'Lees meer',
      categoryDiscover: 'Découvrez',
    },
    search: {
      title: 'Zoeken naar...',
      shops: 'Winkels',
      seeAll: 'Alles',
      results: 'Resultaat',
      learnMore: 'Lees meer',
      restaurants: 'Eten & Drinken',
      noResults: 'Geen resultaten',
      shopsAndRestaurants: 'Winkels',
      services: 'Diensten',
      ourServices: 'Diensten',
      offers: 'Offers',
      ourOffers: 'Nos Offres',
      noOffers: 'Aucune offre disponible pour le moment',
      events: 'Events',
      ourEvents: 'Events',
      showAll: 'Tout Afficher',
      promos: '1 promos',
      news: 'Nieuws',
    },
    shops: {
      ourShopsAndRestaurants: 'Nos Boutiques & Restaurants',
      discover: 'Découvrir',
      ourShops: 'Winkels',
      ourRestaurants: 'Eten & Drinken',
      byDiscount: 'Par Promotion',
      byCategory: 'Categorieën',
      byAll: 'Alle',
      search: 'Zoeken',
      sorryNoDiscount: "Désolé, pas de promo aujourd'hui.",
      discountSingular: 'promo',
      discountPlural: 'promos',
      learnMore: 'Lees meer',
      noDealsAvailable: 'Aucune promotion disponible',
    },
    shop: {
      takeMeThere: "ROUTE",
      openings: "Openingstijden"
    },
    services: {
      mostViewed: 'Les plus consultés',
      ourServices: 'Diensten',
      learnMore: 'En savoir plus',
      availableHome: "Disponibles à l'accueil",
      otherServices: 'Autres services',
      others: 'Autres',
      toilets: 'Toiletten',
      lift: 'Lift',
      infopoint: 'Infobalie \'The Point\'',
      atm: 'ATM',
      play: 'Play & Relax'
    },
    offers: {
      ourOffers: 'Nos Offres',
    },
    events: {
      title: 'Nieuws & Events',
      rightNow: 'Nu...',
      rightNowPart1: 'En ce',
      rightNowPart2: 'moment...',
      events: 'Events',
      toCome: 'à venir',
      toComeCapitalize: 'À venir',
      discover: 'Découvrir',
      ourNews: 'Nieuws',
      ourEvents: 'Events',
    },
    map: {
      takeMe: "Route",
      qrTheMap: 'De kaart',
      qrOnYour: 'op je telefoon',
      myPosition: 'Mijn positie',
      myDestination: 'Mijn bestemming',
      from: 'Van',
      to: 'Tot',
      navigation: {
        previous: 'Vorig',
        next: 'Volg.',
        cancel: 'Annuleren',
      },
      floor: 'Floor',
    },
    general: {
      learnMore: 'Lees meer',
      rightNow: 'En ce moment...',
    },
    eventCard: {
      news: 'Nieuws',
    },
    pageFilterBar: {
      search: 'Recherche',
    },
    days: {
      mon: 'Maandag',
      tue: 'Dinsdag',
      wed: 'Woensdag',
      thu: 'Donderdag',
      fri: 'Vrijdag',
      sat: 'Zaterdag',
      sun: 'Zondag',
    },
    categories: {
      services: {
        NosCartes: 'Nos Cartes',
        BesoinDe: 'Besoin de ...',
        ObjetsTrouves: 'Objets perdus',
        EnfantsEtNourissons: 'Enfants & Nourrissons',
        Divertissement: 'Divertissement',
        BornesInteractives: 'Bornes Interactives',
        PMR: 'PMR',
        toilette: 'Toilettes',
      },
    },
    screensaver: {
      main: 'TOUCHEZ L’ECRAN',
      touch: 'Tik op het scherm',//Tik op het scherm voor meer informatie!
      toKnowMore: 'voor meer informatie!',
      sub: [

        'LES MAGASINS',
        'LES RESTAURANTS',
        'LES EVENEMENTS',
        "C'EST FACILE !",
        'OU SONT LES TOILETTES ?',
        'LES SERVICES',
        'LE PLAN DU CENTRE',
        'OU SONT LES TOILETTES?',
      ],
    },
    social: {
      followus:'VOLG ONS',
      onFB: 'OP FACEBOOK',
      onIG: 'OP INSTAGRAM',
      join: 'Volg onze socials: ',
      scanQR: 'Scan de QR code.'
    },
    giftcard: {
      title: 'Doe 90 winkels cadeau!',
      subtitle: 'Doe al onze winkels cadeau!',
      body: 'Heb je geen idee welk cadeau je moet geven en ben je bang om de verkeerde keuze te maken? Heb je weinig tijd om in de winkels rond te snuffelen? Combineer het nuttige aan het aangename en doe een Gift Card cadeau.\n\nDeze kaart is een jaar geldig en kan worden opgeladen voor een bedrag naar keuze tussen 20€ en 250€. De kaart is ook geldig in meer dan 100 deelnemende winkels en restaurants.\n\nBiedt de vrijheid om te kiezen en maak iedereen gelukkig!',
      buyBtn: 'Koop hier een Gift Card',
      balanceBtn: 'Controleer mijn resterend bedrag',
      buyModal: {
        scanHere: 'Scan de QR code hieronder om een Gift Card te kopen!',
        orBuy: 'Te koop bij The Point of \nvia de webshop door de QR code te scannen'
      },
      balanceModal: {
        instructions: 'Voer de token van uw Gift Card in, bestaande uit 9 cijfers op de achterkant.',
        send: 'Verzenden'
      }
    },
    newsletter: {
      title: 'Blijf altijd up-to-date',
      subtitle: 'Schrijf je in voor onze nieuwsbrief en blijf op de hoogte van nieuws, events en aanbiedingen van winkelcentrum en haar winkeliers. ',
      fields: {
        email: 'Email',
        firstName: 'Voornaam',
        DOB: 'Geboortedatum',
        family: 'Jouw huishouden (optioneel)',
        families: {
          select: 'Kies een optie ...',
          house: 'Inwonend',
          single: 'Alleenwonend, geen kinderen',
          couple: 'Samenwonend, geen kinderen',
          kids: 'Gezin met kinderen',
          kidsLeft: 'Kinderen zijn uit huis'
        },
        postCode: 'Postcode (optioneel)',
        iHaveRead: 'Ik ga akkoord met het ',
        theTCS: 'privacybeleid',
        iAccept: 'Ik geef toestemming om mijn e-mailadres en persoonsgegevens te gebruiken voor het toezenden van nieuws, events en aanbiedingen via e-mail.',
        submit: 'Verzenden'
      }
    }
  },
};
