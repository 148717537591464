<template>
  <Content :background="`/${slug}/img/newsletter.jpeg`">
    <template #header>
      <h1>{{ $t('newsletter.title') }}</h1>
    </template>
    <div class="page--giftcard">
      <template v-if="response">
        <p v-html="response"></p>
      </template>
      <template v-else>
        <h1>
          {{ $t('newsletter.subtitle') }}
        </h1>
        <div class="form">
          <div class="row">
            <div class="cell email">
              <div class="input-group">
                <label>{{ $t('newsletter.fields.email') }} *</label>
                <input
                  type="email"
                  name="email"
                  :value="values.email"
                  @click="setTarget($event.target)"
                  @blur="active = undefined"
                />
              </div>
            </div>
            <div class="cell">
              <div class="input-group">
                <label>{{ $t('newsletter.fields.firstName') }} *</label>
                <input
                  type="text"
                  name="fname"
                  :value="values.fname"
                  @click="setTarget($event.target)"
                  @blur="active = undefined"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="cell">
              <div class="input-group">
                <label>{{ $t('newsletter.fields.DOB') }} *</label>
                <input type="date" name="birthdate" @change="date = $event.target.value" />
              </div>
            </div>
            <div class="cell">
              <div class="input-group dropdown">
                <label>{{ $t('newsletter.fields.family') }}</label>
                <select
                  name="household"
                  id="household"
                  class="valid"
                  aria-invalid="false"
                  :value="household"
                  @change="household = $event.target.value"
                >
                  <option value="0" selected="">{{ $t('newsletter.fields.families.select') }}</option>
                  <option value="1">{{ $t('newsletter.fields.families.house') }}</option>
                  <option value="2">{{ $t('newsletter.fields.families.single') }}</option>
                  <option value="3">{{ $t('newsletter.fields.families.couple') }}</option>
                  <option value="4">{{ $t('newsletter.fields.families.kids') }}</option>
                  <option value="5">{{ $t('newsletter.fields.families.kidsLeft') }}</option>
                </select>
              </div>
            </div>
            <div class="cell">
              <div class="input-group">
                <label>{{ $t('newsletter.fields.postCode') }}</label>
                <input
                  type="text"
                  name="zip"
                  :value="values.zip"
                  @focus="setTarget($event.target)"
                  @blur="active = undefined"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="cell keyboard">
              <div class="keyboard-wrapper" :class="{ inactive: !active, date: active?.type === 'date' }">
                <g-keyboard ref="keyboard" :input="query" :layout="layout" @onChange="onChange" />
              </div>
            </div>
            <div class="cell terms">
              <div class="checkbox">
                <label for="terms">
                  <input type="checkbox" id="terms" name="privacy" @change="terms = $event.target.checked" />
                  <span></span>
                  <small>
                    {{ $t('newsletter.fields.iHaveRead') }}
                    <a href="#" @click="openModal">{{ $t('newsletter.fields.theTCS') }}</a>
                  </small>
                </label>
              </div>
              <div class="checkbox">
                <label for="accept">
                  <input type="checkbox" id="accept" name="subscribe" @change="accept = $event.target.checked" />
                  <span></span>
                  <small>
                    {{ $t('newsletter.fields.iAccept') }}
                  </small>
                </label>
              </div>
              <g-button :disabled="formDataInvalid" @click="submitForm">{{ $t('newsletter.fields.submit') }}</g-button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="loading">
        <div class="loading">
          <g-icon-loading />
        </div>
      </template>
    </div>
    <g-modal v-if="modalOpen" target="content" @close="closeModal" class="terms-modal">
      <terms />
    </g-modal>
  </Content>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';

import Content from '@/components/layout/content/Content.vue';
import Terms from '@/components/misc/Terms.vue';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Content,
    Terms,
  },
  setup() {
    const active = ref<HTMLInputElement>();
    const query = ref(''),
      keyboard = ref(),
      terms = ref(false),
      accept = ref(false),
      household = ref<number>(0),
      date = ref<string>(),
      values = ref<any>({}),
      response = ref<string>(),
      loading = ref(false);

    const modalOpen = ref(false);

    function openModal() {
      modalOpen.value = true;
    }

    function closeModal() {
      modalOpen.value = false;
    }

    const formData = computed(() => ({
      fname: values.value['fname'],
      email: values.value['email'],
      zip: values.value['zip'],
      privacy: terms.value ? 'on' : 'off',
      subscribe: accept.value ? 'on' : 'off',
      household: household.value,
      birthdate: date.value,
      mall: project.config.newsletter_registration.mall,
      lang: project.config.newsletter_registration.lang,
    }));

    const formDataInvalid = computed(() => {
      const data = formData.value;
      return !data.email || !data.fname || !data.birthdate || data.privacy === 'off' || data.subscribe === 'off';
    });

    function setTarget(input: HTMLInputElement) {
      query.value = input?.value;
      active.value = input;
      keyboard.value.keyboard?.setOptions({
        inputName: input.name,
      });
      input.value = '';
      setTimeout(() => {
        input.value = query.value;
        keyboard.value.keyboard.setCaretPosition(input.value.length);
      }, 10);
    }

    function onChange(e: string) {
      if (active.value) {
        values.value[active.value.name] = e;
        active.value.value = e;
      }
    }

    function reset() {
      if (active.value) {
        active.value.blur();
      }
      query.value = '';
      values.value = {};
      active.value = undefined;
      accept.value = false;
      terms.value = false;
      household.value = 0;
      date.value = '';
      keyboard.value?.keyboard?.clearInput();
    }

    //Veuillez remplir les champs obligatoires

    function submitForm() {
      var Player = (window as any).Player;
      if (!Player) return;
      loading.value = true;

      setTimeout(() => {
        const _response = Player.axiosReq({
          url: `https://cloud.m.wereldhave.com/processSignup`,
          method: 'POST',
          data: JSON.stringify(formData.value),
          headers: {
            formData: true,
            referer: project.config.newsletter_registration.referer,
          },
        });

        if (_response && _response.status === 200) {
          response.value = 'Merci ! Vous recevrez dans quelques instants un e-mail pour finaliser votre inscription.';
        } else {
          response.value = 'Désolé, il semble que nous ayons des difficultés pour vous inscrire.';
        }

        loading.value = false;

        setTimeout(() => reset(), 5000);
      }, 500);
    }

    return {
      active,
      query,
      onChange,
      keyboard,
      setTarget,
      submitForm,
      terms,
      accept,
      household,
      date,
      modalOpen,
      openModal,
      closeModal,
      response,
      loading,
      formData,
      values,
      formDataInvalid,
      layout: {
        default: [
          'A Z E R T Y U I O P - {bksp}  0 1 2 3',
          'Q S D F G H J K L M @ & _   4 5 6',
          'W X C V B N {space} {enter} . #   7 8 9',
        ],
      },
      slug: import.meta.env.VITE_PROJECT_SLUG,
    };
  },
});
</script>

<style lang="scss" scoped>
.page--giftcard {
  position: relative;
  padding: 4rem;
  background: white;
  flex: 1;
  overflow: hidden;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.5);
  }

  --keyboard-key-spacing: 0.25rem;
  --keyboard-background-active: #2a7d9e;
  --keyboard-background-numpad-active: var(--theme-color);
  //--keyboard-background: #222;
  //--keyboard-background-numpad: #777;

  h1 {
    text-align: left;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: var(--theme-color) !important;
  }

  .form {
    /*    --gap: 0.5rem;*/

    display: flex;
    flex-direction: column;
    /*    gap: var(--gap);*/
    outline: 2px solid red !important;
    margin-top: 2rem;

    .row {
      display: flex;
      /*      gap: var(--gap);*/
      outline: 2px solid red !important;
      width: 100%;

      .cell {
        display: flex;
        flex: 1;
        padding: 1rem;

        justify-content: flex-start;
        align-items: flex-start;

        &.keyboard,
        &.email {
          justify-content: center;
          align-items: center;

          /*          min-width: calc(66% + var(--gap));*/
        }

        &.terms {
          flex-direction: column;
          /*          gap: 1rem;*/
          outline: 2px solid red !important;

          .g-btn {
            width: 100%;
            background: var(--theme-color);
            color: white;
            font-weight: bold;
            &:disabled {
              opacity: 0.4;
            }
          }

          .checkbox {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            /*            gap: 2rem;*/
            outline: 2px solid red !important;

            label {
              display: flex;
              /*              gap: 1rem;*/
              outline: 2px solid red !important;

              input {
                display: none;

                &:checked + span:before {
                  content: '✔';
                }
              }

              a {
                font-weight: bold;
                color: var(--theme-color);
              }

              small {
                display: flex;
                flex-direction: column;
                text-align: left;
                /*                gap: 0.5rem;*/
                outline: 2px solid red !important;
              }

              span {
                position: relative;
                float: right;
                min-height: 2rem;
                min-width: 2rem;
                height: 2rem;
                width: 2rem;
                border-radius: 0.125rem;
                cursor: pointer;
                display: block;
                justify-content: center;
                align-items: center;
                background: #f2f6f9;

                &:before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--theme-color);
                  font-weight: bold;
                  font-size: 1.5rem;
                  line-height: 1.5rem;
                }
              }
            }
          }
        }

        &.keyboard {
          .keyboard-wrapper {
            &.inactive {
              opacity: 0.25;
              pointer-events: none;
            }

            &.date {
              :deep(.hg-button) {
                &:not(.hg-numpad):not([data-skbtn='{bksp}']) {
                  opacity: 0.25;
                  pointer-events: none;
                }
                &[data-skbtn='#'] {
                  opacity: 0.25;
                  pointer-events: none;
                }
              }
            }
          }
        }

        .input-group {
          display: flex;
          flex-direction: column;
          /*          gap: var(--gap);*/
          outline: 2px solid red !important;
          flex: 1;

          label {
            text-align: left;
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: bold;
          }

          input,
          select {
            border: none;
            background: #f2f6f9;
            /*            padding: var(--gap);*/
            height: 4rem;
            font-size: 1.75rem;
            line-height: 1.75rem;
            padding-left: 1rem;
          }

          &.dropdown {
            position: relative;
            &:after {
              content: ' ';
              position: absolute;
              bottom: 2.5rem;
              right: 2rem;
              border: solid black;
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 3px;
              transform: translateY(100%) rotate(45deg);
            }
          }
        }
      }
    }
  }
}
</style>
