import { createRouter, createWebHistory } from 'vue-router';
import { useAnalytics } from "glooh-globals/src/plugins/misc/analytics"

import App from '@/views/App.vue';
import Home from '@/views/pages/Home.vue';
import Menu from '@/views/pages/Menu.vue';
import Stores from '@/views/pages/Stores.vue';
import Agendas from '@/views/pages/Agendas.vue';
import Promos from '@/views/pages/Promos.vue';
import Services from '@/views/pages/Services.vue';
import Search from '@/views/pages/Search.vue';
import Plans from '@/views/pages/Plans.vue';
import NewsLetter from '@/views/pages/NewsLetter.vue';
import GiftCard from '@/views/pages/GiftCard.vue';
import Horaires from '@/views/pages/Horaires.vue';
import Socials from '@/views/pages/Socials.vue';
import Cam from '@/views/pages/Cam.vue';

import Store from '@/views/pages/single/Store.vue';
import Agenda from '@/views/pages/single/Agenda.vue';
import Service from '@/views/pages/single/Service.vue';

function emptyPage(pageTitle: string) {
  return {
    template: `
      <div class="page--${pageTitle.toLowerCase()}">
        <h1>${pageTitle}</h1>
      </div>
    `,
  };
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: App,
      children: [
        {
          path: 'home/:view?/:id?',
          name: 'home',
          component: Home,
        },
        {
          path: 'menu',
          name: 'menu',
          component: Menu,
        },
        {
          path: 'search/:result?',
          name: 'search',
          component: Search,
        },
        {
          path: 'stores/single/:id',
          name: 'store',
          component: Store,
        },
        {
          path: 'stores/:type?/:filter?',
          name: 'stores',
          component: Stores,
        },
        {
          path: 'services/single/:id',
          name: 'service',
          component: Service,
        },
        {
          path: 'services/:view?/:id?',
          name: 'services',
          component: Services,
        },
        {
          path: 'horaires',
          name: 'horaires',
          component: Horaires,
        },
        {
          path: 'giftcard',
          name: 'giftcard',
          component: GiftCard,
        },
        {
          path: 'newsletter',
          name: 'newsletter',
          component: NewsLetter,
        },
        {
          path: 'socials',
          name: 'socials',
          component: Socials,
          meta: { full: true },
        },
        {
          path: 'promos/:filter?',
          name: 'promos',
          component: Promos,
        },
        {
          path: 'agendas/single/:id',
          name: 'agenda',
          component: Agenda,
        },
        {
          path: 'agendas/:type?/:filter?',
          name: 'agendas',
          component: Agendas,
        },
        {
          path: 'plan/:placeId?',
          name: 'plan',
          component: Plans,
        },
        {
          path: 'cam',
          name: 'cam',
          component: Cam,
        },
        {
          path: '',
          name: 'root',
          redirect: 'home',
        },
      ],
    },
    {
      path: '/:catchAll(.*)',
      redirect: 'home',
    },
  ],
});

router.beforeEach((to) => {
  const { page } = useAnalytics();
  page({ title: document.title, event: 'view', content: to.name, resource: to.params.id });
})

export default router;
