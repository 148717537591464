<template>
  <component class="g-btn" :class="classes" :disabled="disabled || loading" :is="is" v-bind="target">
    <div v-if="background" class="background" :style="{ backgroundImage: `url(${background})` }"></div>
    <component v-if="icon" v-vis="!loading" :is="`g-icon-${icon}`" :style="style" />
    <div v-if="$slots.icon" class="icon-wrapper">
      <slot name="icon" />
    </div>
    <span class="content" v-if="$slots.default" v-vis="!loading"><slot /></span>
    <div v-if="loading" class="loader"><g-icon-loading /></div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    iconSize: {
      type: [Number, String],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: undefined,
    },
    to: {
      default: undefined,
    },
    href: {
      default: undefined,
    },
  },
  computed: {
    style(): object {
      return {
        ...(this.iconSize ? { width: this.iconSize, height: this.iconSize } : {}),
      };
    },
    is(): string {
      return this.to ? 'router-link' : this.href ? 'a' : 'button';
    },
    target(): object {
      return this.to ? { to: this.to } : this.href ? { href: this.href } : {};
    },
    classes(): object {
      return {
        'g-btn--loading': this.loading,
        'g-btn--outline': this.outline,
        'g-btn--transparent': this.transparent,
        'g-btn--rounded': this.rounded,
        'g-btn--no-hover': this.noHover,
        'g-btn--background': !!this.background,
        'g-btn--disabled': !!this.disabled,
      };
    },
  },
  mounted() {
    //console.log(this.$props);
  },
});
</script>

<style lang="scss">
.g-btn {
  position: relative;
  display: inline-flex;
  flex-direction: var(--button-direction);
  justify-content: space-evenly;
  align-items: center;

  background: var(--button-background);
  border: none;
  outline: none;

  white-space: var(--button-white-space);
  gap: var(--button-spacing);
  margin: var(--button-margin);
  padding: var(--button-padding);

  .content {
    padding: var(--button-padding);
  }

  * {
    z-index: 1;
    visibility: inherit !important;
  }

  .icon-wrapper {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  svg {
    width: 1em;
    height: 1em;
  }

  svg,
  .loader,
  span,
  .background {
    pointer-events: none;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (hover: hover) {
    &:not(:disabled) {
      &:hover {
        cursor: pointer;

        &:not(.g-btn--no-hover) {
          background: var(--button-background-hover);
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--button-background-disabled);
  }

  &:not(:disabled) {
    &:active,
    &:not(.g-btn--no-hover):active {
      background: var(--button-background-active);
    }
  }

  &--rounded {
    border-radius: var(--app-border-radius);
  }

  &--outline {
    border: var(--app-border-size) solid var(--app-border-color);
  }

  &--loading,
  &--loading:disabled {
    cursor: wait;
  }

  &--transparent {
    background: transparent;
  }

  &--background {
    padding: 1.5vmax;

    span {
      color: var(--button-image-foreground);
      padding: 0.5vmax;

      transition: all 0.25s linear;
    }

    .background {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: var(--button-image-filter);
      transition: all 0.25s linear;
    }

    @media (hover: hover) {
      &:not(:disabled) {
        &:hover {
          span {
            background: var(--button-image-background);
          }
          .background {
            filter: var(--button-image-filter-hover);
          }
        }
      }
    }
  }
}
</style>
