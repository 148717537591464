<template>
  <div id="app" :class="{ disabledUI, timedOut: wasTimedOut }">
    <template v-if="$route.meta.full">
      <router-view />
    </template>
    <template v-else>
      <cdp-header @disabled="setDisabled" @abled="setAbled" />
      <div class="main-content">
        <div class="page-wrapper" :style="{ backgroundImage: `url(/${slug}/img/background.png)` }">
          <router-view />
        </div>
        <g-modal-zone zone="content" />
      </div>
      <!-- <div class="footer">
        <g-button class="toggle-disability" @click="$emit('application')">
          <template #icon><g-icon-nav-app /></template>
          {{$t('footer.application')}}
        </g-button>
        <g-button class="toggle-disability" @click="$emit('disabled')">
          <template #icon><g-icon-nav-pmr /></template>
          {{$t('footer.disabled')}}
        </g-button> -->
      <!-- <router-link :to="{ name: 'cam' }" class="g-btn socials" style="--button-direction: row;">
          <g-icon-camera />
          <div class="socials-text">
            <b>Conviértete en la estrella de la pantalla grande: ¡haz tu foto ahora!</b>
            <small>@oasizmadrid</small>
          </div>
          <div class="images">

          </div>
        </router-link>
      </div> -->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CDPHeader from '@/components/layout/header/CDPHeader.vue';
import { useAnalytics } from 'glooh-globals/src/plugins/misc/analytics';

export default defineComponent({
  components: {
    'cdp-header': CDPHeader,
  },
  setup() {
    const route = useRoute(),
      router = useRouter(),
      analytics = useAnalytics();

    const disabledUI = ref(false);

    function setDisabled() {
      disabledUI.value = true;
      analytics.track('ui.disabled', { disabled: true });
    }

    function setAbled() {
      disabledUI.value = false;
      analytics.track('ui.disabled', { disabled: false });
    }

    function onTimeout() {
      if (route.name !== 'home') router.push({ name: 'home' });
      disabledUI.value = false;
    }

    onMounted(() => {
      if (import.meta.env.PROD && route.name !== 'home') {
        router.push({ name: 'home' });
      }
    });

    return { setDisabled, setAbled, disabledUI, onTimeout, slug: import.meta.env.VITE_PROJECT_SLUG };
  },
});
</script>

<style lang="scss" scoped>
#app {
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;

  .page-wrapper {
    height: 100%;
    overflow: hidden;
  }

  .modal-zone {
    --modal-overflow: visible;
    --modal-width-max: 100%;
    --modal-height-max: 100%;
    --modal-overlay-background: #000000cc;
    z-index: 1000;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  padding: 35px;
  /*  gap: 1.5vmax;*/
  outline: 2px solid red !important;
  background-color: white;

  --button-background: transparent;
  --button-direction: column;
  --button-spacing: 0;

  .g-btn {
    height: 100%;
    width: 120px;
    justify-content: flex-end;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .socials {
    background-color: #c3e7eb;
    background: rgba(var(--theme-color-rgb), 0.3);
    flex: 1;
    justify-content: space-between;
    padding: 0 2vmax;
    /*    gap: 2vmax;*/
    outline: 2px solid red !important;
    .socials-text {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    .images {
      flex: 1;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
