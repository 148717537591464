<template>
  <nav class="main-nav">
    <g-navbar param="type" :routes="routes" />
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import IconHere from '@/components/icons/IconHere.vue';

export default defineComponent({
  components: {
    IconHere,
  },
  data: function () {
    return {
      routes: [
        {
          name: 'home',
          text: this.$t('navbar.home'),
          icon: 'nav-home',
        },
        {
          name: 'search',
          text: this.$t('navbar.search'),
          icon: 'nav-search',
          params: { result: 'all' },
        },
        // {
        //   name: 'menu',
        //   text: this.$t('navbar.menu'),
        //   icon: 'nav-menu',
        // },
        {
          name: 'plan',
          text: this.$t('navbar.plan'),
          icon: 'here',
        },
        // {
        //   name: 'cam',
        //   text: this.$t('navbar.camera'),
        //   icon: 'camera',
        // },
        /*
        {
          name: 'home',
          text: this.$t('navbar.home'),
          icon: 'nav-home',
        },
        {
          name: 'search',
          text: this.$t('navbar.search'),
          icon: 'nav-search',
          params: { result: 'all' },
        },
        {
          name: 'horaires',
          text: this.$t('navbar.openings'),
          icon: 'nav-time',
          disabled: false,
        },
        {
          name: 'stores',
          text: this.$t('navbar.shops'),
          icon: 'nav-stores',
          params: { type: 'shops', filter: 'all' },
        },
        {
          name: 'stores',
          text: this.$t('navbar.restaurants'),
          icon: 'nav-restaurants',
          params: { type: 'restaurants', filter: 'all' },
        },
        {
          name: 'services',
          text: this.$t('navbar.services'),
          icon: 'nav-services',
          disabled: false,
        },
        {
          name: 'giftcard',
          text: this.$t('navbar.giftcard'),
          icon: 'nav-gift',
          disabled: false,
        },
        {
          name: 'agendas',
          text: this.$t('navbar.agendas'),
          icon: 'nav-agenda',
          params: { type: 'now', filter: 'all' },
        },
        {
          name: 'newsletter',
          text: this.$t('navbar.newsletter'),
          icon: 'nav-newsletter',
          disabled: false,
        },*/
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.main-nav {
  --navbar-anchor-background: transparent;
  --navbar-display: inline-flex;
  --navbar-anchor-foreground: black;
  --navbar-anchor-foreground-active: var(--theme-color);
  --button-background-active: transparent;
  --button-white-space: wrap;
  --button-direction: column;
  --button-padding: 0;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: black !important;

  .extra {
    display: flex;
    flex-direction: row;
    /*    gap: 2rem;*/
    outline: 2px solid red !important;

    div {
      display: flex;
      flex: 1;
    }

    button,
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;

      flex: 1;
      height: 5rem;
      border: none;
      background: var(--theme-color);
      color: white;
      /*      gap: 1rem;*/
      outline: 2px solid red !important;

      font-size: 1.5rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      font-weight: normal;

      cursor: pointer;

      &.socials {
        background: #222d33;
      }

      img,
      svg {
        height: 2rem;
        filter: brightness(0) invert(1);
      }
    }
  }

  :deep(.nav-wrapper) {
    .navbar {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .nav-item,
      .g-btn {
        height: 100%;
        width: 216px !important;
        justify-content: center;
        padding: 0;
      }

      .g-btn {
        padding: 0 2rem;
        height: 165px !important;
        width: 216px !important;
      }
    }

    .background {
      &:after {
        content: ' ';

        position: absolute;
        left: 0;
        right: 0;
        top: calc(100%);
        height: 0.2vmax;
        background: var(--theme-color);
      }
    }
  }

  .footer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .nav-wrapper {
      height: 4.5rem;
      transition: height 0.25s ease-in-out;

      background: white;
      z-index: 1;

      :deep(.nav-item:first-child) {
        --button-direction: row-reverse;
        a svg {
          transition: transform 0.25s ease-in-out;
        }
      }

      &.visible {
        height: 24rem;

        :deep(.nav-item:first-child) {
          --button-direction: row-reverse;
          a {
            color: var(--navbar-anchor-foreground-active);
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      /*      gap: 1.5rem;*/
      outline: 2px solid red !important;
      padding: 2rem;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .map-link {
        display: flex;
        justify-content: center;
        align-items: center;
        /*        gap: 2rem;*/
        outline: 2px solid red !important;

        height: 130px;
        background: #efefef;
        color: black;

        font-size: 2rem;

        background: #efefef url('/#{$projectSlug}/img/map-preview.png') no-repeat;
        background-size: cover;
        background-position: center center;
        filter: grayscale(0.5);

        svg,
        img {
          height: 3rem;
        }
      }
    }
  }
}
</style>
