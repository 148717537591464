import { ref, Ref } from 'vue';

import Fuse from 'fuse.js';
import { getFn } from '../utils/fuse';
import moment from 'moment';

function isInDateRange(date1: string, date2: string) {
  return moment().isBetween(
    moment(date1, 'YYYY-MM-DDThh:mm:ssZ'),
    moment(date2, 'YYYY-MM-DDThh:mm:ssZ'),
    undefined,
    '[]',
  );
}

function isDateValid(date1: string, date2: string) {
  if (date1 && date2) {
    return isInDateRange(date1, date2);
  } else if (date1) {
    return moment().isAfter(moment(date1, 'YYYY-MM-DDThh:mm:ssZ'));
  } else if (date2) {
    return moment().isBefore(moment(date2, 'YYYY-MM-DDThh:mm:ssZ'));
  } else {
    return true;
  }
}

export default function <T extends DirectusRecord>() {
  const fuse = new Fuse([] as T[], {
    threshold: 0.3,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    keys: ['title', 'description-long', 'description-short'],
    getFn,
  });

  const entries = ref<T[]>([]);

  function all(): T[] {
    return (entries as Ref<T[]>).value;
  }

  function active(): T[] {
    return (entries as Ref<T[]>).value.filter((a: any) => {
      if (!isDateValid(a.date_start, a.date_end)) return false;
      if (!isDateValid(a.date_from, a.date_to)) return false;
      return true;
    });
  }

  function valid(): T[] {
    return (entries as Ref<T[]>).value;
  }

  function update(newEntries: T[]) {
    (entries as Ref<T[]>).value = newEntries;
    fuse.setCollection(newEntries);
  }

  function find(id: number): T | undefined {
    return all().find((e: DirectusRecord) => e && e.id === id);
  }

  function findBy(obj: any): T[] | undefined {
    return all().filter((e: any) => {
      if (e) {
        for (let key of Object.keys(obj)) {
          if (obj[key] !== e[key]) {
            return false;
          }
        }
        return true;
      }
      return false;
    });
  }

  function search(key: string, fetchAll: boolean): T[] {
    if (key) return fuse.search(key).map((result) => result.item);
    if (fetchAll) return all();
    return [];
  }

  return {
    entries,
    all,
    active,
    valid,
    update,
    find,
    search,
    findBy,
    //withCategories: (...category: string[]) =>
    //state.entries.find((e: any) => e && e.categories && e.categories.some((c: string) => category.indexOf(c) >= 0)),
  } as ProjectCollection<T>;
}
