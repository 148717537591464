<template>
  <router-link :to="to || { name: 'store', params: { id: store?.id } }" class="store-card">
    <div class="store-top">
      <cimg v-if="store?.logo" :src="store?.logo" />
    </div>
    <div class="store-info">
      <div class="text">
        <p class="name">{{ getLocaleValue(store?.title) }}</p>
        <p class="categories">
          <span>{{ store?.categories?.slice(0, 2).join(', ') }}</span>
        </p>
      </div>

      <div class="actions">
        <span class="fom">Found out more</span>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

export default defineComponent({
  props: {
    store: {
      type: Object,
      default: undefined,
    },
    to: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    getLocaleValue,
  },
});
</script>

<style lang="scss" scoped>
.store-card {
  --button-direction: column;
  --button-padding: 1vmax 4vmax;
  --navbar-display: flex;
  --button-background-active: transparent;
  --button-background-hover: transparent;
  --button-background: white;

  display: flex;
  flex-direction: row;

  width: 350px;
  height: 216px;
  min-width: 350px;
  min-height: 216px;
  overflow: hidden;
  background: transparent !important;
  padding: 0.125rem;

  .store-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1;
    padding: 1.5rem;

    width: 160px;
    min-width: 160px;
    max-width: 160px;

    background: #ebebeb !important;

    .g-btn {
      box-shadow: 0px 0px 20px #0000000d;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .store-info {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    background-color: #f5f5f3;
    flex: 1;
    position: relative;

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      padding: 1rem;
    }

    .actions {
      position: absolute;
      bottom: 0;
      right: 0;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: 0.25rem;
    }

    .name {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .fom {
      flex: 0;
      white-space: nowrap;
      font-size: 0.5rem;
      padding: 1rem;
    }

    .categories {
      display: flex;
      flex-direction: column;
      align-items: start;
      font-size: 0.75rem;
    }
  }

  :deep(.g-btn) {
    flex: 1;
    justify-content: center;

    .icon-wrapper {
      width: 6vmax;
      height: 6vmax;
    }
  }
}
</style>
