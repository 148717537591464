<template>
  <div class="page" :class="{ modal, compact, full }">
    <div class="modal-bg" v-if="modal" @click="$router.go(-1)" />
    <div v-if="$slots.header" class="page-header" :class="{ white: background }">
      <a v-if="!modal" href="#" @click="$router.go(-1)" class="back router-link-exact-active router-link-active">
        <IconArrowLeft style="fill: black" />
      </a>
      <div class="header-container">
        <slot name="header" />
      </div>
      <div v-if="!modal" />
      <a
        v-if="modal"
        href="#"
        @click="$router.go(-1)"
        class="back router-link-exact-active router-link-active"
        style="margin-left: 2rem"
      >
        <IconClose style="fill: black" />
      </a>
    </div>
    <div class="content-child">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconClose from 'glooh-globals/src/components/icons/IconClose.vue';
import IconArrowLeft from 'glooh-globals/src/components/icons/IconArrowLeft.vue';

export default defineComponent({
  components: {
    IconClose,
    IconArrowLeft,
  },
  props: {
    background: String,
    modal: Boolean,
    compact: Boolean,
    full: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  position: relative;
  z-index: 512;
  padding: 4rem;

  &.modal > .modal-bg {
    content: ' ';
    background: rgba(0 0 0 / 0.9);

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 510;
  }

  :deep([class^='page--']) {
    z-index: 2;
  }

  .page-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;

    background: white;
    z-index: 0;

    filter: brightness(0.75);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .content-child {
    flex: 1;
    z-index: 512;
    padding: 2rem;
    padding-top: 0;
    background: white;
    overflow-y: auto;
    overflow-x: visible;
  }

  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: black;
    width: 100%;
    z-index: 512;
    padding: 2rem;
    padding-bottom: 1rem;
    background: white;

    &.white {
      color: white;
    }

    :deep(h1) {
      font-size: 3rem;
      line-height: 4rem;
      padding: 0;
      font-family: 'Roboto';
      color: rgb(179, 149, 71);
      // text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    }

    .header-container {
      flex: 1;
    }

    :deep {
      * {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: var(--header-dir);
      }

      img {
        width: var(--header-img-width, 30rem);
        max-width: 100%;
        height: 20rem;
        max-height: 100%;
        object-fit: contain;
      }

      svg {
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        min-height: 4rem;
        margin-right: 0.5rem;
        fill: var(--theme-color);
        stroke: var(--theme-color);
      }

      a {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }
    }
  }

  &.compact {
    padding: 2rem;

    .content-child {
      padding: 0;
    }
  }

  &.full {
    padding: 0;

    .content-child {
      padding: 0;
      background: #fdfcfc;
    }

    .page-header {
      background: #fdfcfc;
    }
  }
}
</style>
