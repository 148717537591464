import { CronJob } from 'cron';
import { reactive } from 'vue';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

import collection from './collection';
import { getLocaleValue } from '../utils/fuse';
import { i18n } from '../i18n';

export const baseURL = 'https://api.staging.avriopolis.gloohtest.co.uk';

const directus = axios.create({
  withCredentials: true,
  headers: { Authorization: 'Bearer cms-sync-tool' },
});

const project = reactive<Project>({
  id: -1,
  updater: undefined,
  config: {},
  events: collection(),
  restaurants: collection(),
  services: collection(),
  shops: collection(),
  promos: collection(),
  menus: collection(),
});

export async function install(options: any) {
  if (options?.id) {
    let synced = false;

    do {
      try {
        await new Promise((res) => setTimeout(res, 1000));
        synced = await sync(options.id);
        console.log('Synced:', synced);
      } catch (e) {}
    } while (!synced);

    if (options?.refreshRate) {
      project.updater = new CronJob(
        options.refreshRate,
        () => {
          try {
            sync(options.id);
          } catch (e) {}
        },
        null,
        false,
      );
      await start();
    }

    return synced;
  }
}

export function search(key: string, fetchAll?: boolean): ProjectSearch {
  return {
    events: project.events.search(key, fetchAll),
    restaurants: project.restaurants.search(key, fetchAll),
    services: project.services.search(key, fetchAll),
    shops: project.shops.search(key, fetchAll),
    promos: project.promos.search(key, fetchAll),
  };
}

function normalise(object: any, resources: any) {
  object.images = [];

  for (let media of object.media ?? []) {
    if (media.types?.includes('banner') || media.types?.includes('banners')) {
      object.banner = `${baseURL}/assets/${media.files_id.id}`; //media.files_id;
    }
    if (media.types?.includes('logo')) {
      object.logo = `${baseURL}/assets/${media.files_id.id}`; //media.files_id;
    }
    if (media.types?.includes('cover')) {
      object.images.push(`${baseURL}/assets/${media.files_id.id}`);
    }
  }

  delete object.media;

  if (object.icon) {
    object.icon = `${baseURL}/assets/${object.icon}`;
  }

  for (let field of object.fields ?? []) {
    const key = field.fields_id.key.replace('-', '_');
    if (key === 'opening_hours') {
      object[key] = field.value;
    } else {
      object[key] = getLocaleValue(field.value);
    }
    if (field.fields_id.type === 'text' && typeof field.value !== 'string') {
      object[key] = `${object[key]}`;
    }
  }
  delete object.fields;

  object.isRestaurant = !!object?.tags?.includes('restaurant');
  object.isShop = !!object?.tags?.includes('shop');

  object.categories = (object.categories?.map((c: any) => getLocaleValue(c.categories_id.name)) ?? []).filter(
    (c: string) => !!c,
  );

  if (object.title) object.keySort = getLocaleValue(object.title, false);

  return object;
}

async function getCollection(name: string, fields = '*.*.*', useProject: boolean = true) {
  let url = `${baseURL}/items/${name}/?limit=-1&fields=${fields}${
      useProject ? `&filter[status]=published&filter[projects_id]=${project.id}` : ''
    }`,
    urlToGet = url;

  const errorData: any = {
    type: 'error',
    'error-type': 'failed-load-collection',
    online: navigator.onLine,
    userAgent: navigator.userAgent,
    resource: url,
    projectId: project.id,
  };

  var Player = (window as any).Player;
  if (typeof Player !== 'undefined') {
    urlToGet = (
      await Player.cachedURL(urlToGet, { forceUpdate: true, headers: { Authorization: 'Bearer cms-sync-tool' } })
    ).cachedURI;
    errorData.kioskId = Player.KIOSK_ID;
  }

  try {
    const response = await directus.get(urlToGet);
    return response.data.data;
  } catch (e: any) {
    errorData.cachedURL = urlToGet;
    errorData.error = e.message ?? e.code;
    axios.post('https://hook.eu1.make.com/uitho207yhyk1bmfx70eto53p5f3uakb', errorData);
  }
}

export async function sync(projectId: number) {
  project.id = projectId;

  try {
    const { data: config } = await axios.get(`/${import.meta.env.VITE_PROJECT_SLUG}/config.json`);
    project.config = config;

    const _i18n = i18n();
    if (_i18n) {
      if (config.locale) {
        _i18n.locale = config.locale;
      }
      if (config.fallbackLocale) {
        _i18n.fallbackLocale = config.fallbackLocale;
      }
    }
  } catch (e) {}

  const categories = await getCollection('categories');
  const points_of_interest = await getCollection('points_of_interest');
  const announcements = await getCollection('announcements');
  const menus = await getCollection('menus', '*');
  const resources = { categories };

  const mapped_poi = _.sortBy(
    points_of_interest.filter((f: any) => !f.tags?.includes('service')).map((e: any) => normalise(e, resources)),
    'keySort',
  );

  project.shops.update(mapped_poi);

  const mapped_ser = points_of_interest
    .filter((f: any) => f.tags?.includes('service'))
    .map((e: any) => normalise(e, resources));
  project.services.update(mapped_ser);

  const mapped_ann = announcements
    .map((e: any) => normalise(e, resources))
    .filter(
      (a) => !a.date_published || moment(a.date_published, 'YYYY-MM-DDThhmmss').isAfter(moment().subtract(6, 'months')),
    );
  mapped_ann.sort((a, b) =>
    moment(b.date_published, 'YYYY-MM-DDThhmmss').diff(moment(a.date_published, 'YYYY-MM-DDThhmmss')),
  );
  /*mapped_ann.sort((a, b) => {
    console.log(a.date_published, b.date_published)
    return 1;
  })*/

  project.events.update(mapped_ann.filter((n: any) => n.type === 'news' || n.type === 'event'));

  const mapped_menus = menus.map((e: any) => normalise(e, resources));
  project.menus.update(mapped_menus);

  return true;
}

export async function start() {
  project.updater!.start();
}

export function getKiosk() {
  return project.config.kiosk[getKioskID()] ?? project.config.kiosk[project.config.kiosk.fallback];
}

export function getKioskID() {
  console.log(window);
  var Player = (window as any).Player;
  if (typeof Player != 'undefined' && Player.active) {
    if (Player.KIOSK_ID) {
      return Player.KIOSK_ID;
    }
  }
  if (window.process && window.process.versions.electron) {
    return window.process.env.KIOSK_ID;
  }

  console.warn(`Running outside Glooh Player\nNo Kiosk_ID set; defaulting to ${project?.config?.kiosk?.fallback}`);
  return project?.config?.kiosk?.fallback;
}

export default project;
