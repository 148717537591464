<template>
  <div ref="keyboard" :class="keyboardClass"></div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'SimpleKeyboard',
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      type: String,
    },
    layout: {
      type: Object,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    this.keyboard = new Keyboard(this.$refs.keyboard, {
      preventMouseDownDefault: true,
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: this.layout,
      theme: 'hg-theme-default hg-layout-default kb-custom-theme',
      display: {
        '{bksp}': '⌫',
        '{shift}': 'shift',
        '{space}': 'ESPACE',
        '{enter}': '➤',
      },
      buttonTheme: [
        {
          class: 'hg-numpad',
          buttons: '1 2 3 4 5 6 7 8 9 0 #',
        },
        {
          class: 'hg-blank',
          buttons: ' ',
        },
      ],
    });
  },
  methods: {
    onChange(input) {
      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-keyboard {
  &.kb-custom-theme {
    background: none;
    padding: var(--keyboard-padding);
    margin: var(--keyboard-margin);
    width: calc(100% - (var(--keyboard-margin) * 2));

    :deep(.hg-row) {
      display: flex;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: var(--keyboard-key-spacing);
      }
    }
  }

  :deep(.hg-button) {
    background: var(--keyboard-background);
    border-radius: 0;
    color: var(--keyboard-foreground);
    flex-grow: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    height: var(--keyboard-key-height);

    &:not(:last-child) {
      margin-right: var(--keyboard-key-spacing);
    }

    &:active {
      background: var(--keyboard-background-active);
    }
    &.hg-standardBtn {
      min-height: var(--keyboard-key-height);
      min-width: var(--keyboard-key-width);
    }
    &.hg-button-enter {
      min-height: var(--keyboard-key-height);
      min-width: calc((var(--keyboard-key-width) * 2) + var(--keyboard-key-spacing));
    }
    &.hg-button-space {
      min-height: var(--keyboard-key-height);
      min-width: calc((var(--keyboard-key-width) * 3) + (var(--keyboard-key-spacing) * 2));
    }
    &.hg-button-bksp {
      min-height: var(--keyboard-key-height);
      min-width: calc((var(--keyboard-key-width) * 2) + var(--keyboard-key-spacing));
    }
    &.hg-blank {
      background: none;
    }
    &.hg-numpad {
      background: var(--keyboard-background-numpad);
      &:active {
        background: var(--keyboard-background-numpad-active);
      }
    }
  }

  @media (max-aspect-ratio: 1/1) {
    width: 100vw;
    transform: scale(0.65);
  }
}

.hg-theme-default {
  overflow: visible;
}
</style>
