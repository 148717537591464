<template>
  <div class="promo-card">
    <div class="promo-top">
      <g-button :to="to || { name: 'promo', params: { id: store?.id } }" :background="getImage(promo?.logo)" />
    </div>
    <div class="promo-info">
      <p class="name">{{ getLocaleValue(store?.title) }}</p>
      <p class="categories">{{ getLocaleValue(promo?.title) }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

import project from 'glooh-globals/src/store/project';
import { getImage } from '@/store/cache';

export default defineComponent({
  props: {
    promo: {
      type: Object,
      default: undefined,
    },
    to: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    store(): any {
      return project.shops.find(this.promo?.store);
    },
  },
  methods: {
    getLocaleValue,
    getImage,
  },
});
</script>

<style lang="scss" scoped>
.promo-card {
  --button-direction: column;
  --button-padding: 1vmax 4vmax;
  --navbar-display: flex;
  --button-background-active: transparent;

  display: flex;
  flex-direction: column;

  width: 18vmax;
  height: 16vmax;
  /*  gap: 2vmax;*/
  outline: 2px solid red !important;
  overflow: hidden;
  background: transparent !important;

  .promo-top {
    background: var(--button-background);
    .g-btn {
      width: 100%;
      height: 12vmax;
    }
  }

  .promo-info {
    width: 100%;
    padding-left: 1vmax;
    text-align: left;
    text-transform: uppercase;

    .name {
      font-weight: bold;
      font-size: 1.25vmax;
    }
  }

  :deep(.g-btn) {
    flex: 1;
    justify-content: center;

    .icon-wrapper {
      width: 6vmax;
      height: 6vmax;
    }
  }
}
</style>
